import * as React from 'react';
import * as watchUtils from '@arcgis/core/core/watchUtils';

const MapContext = React.createContext();

const mapReducer = (state, action) => {
  switch (action.type) {
    case 'setMapView': {
      const { view, layers, widgets } = action.payload;
      return {
        ...state,
        mapView: view,
        mapLayers: layers,
        mapWidgets: widgets,
        loaded: true,
      };
    }
    case 'setReady': {
      return { ...state, ready: action.payload };
    }
    case 'openPopup': {
      if (action.payload?.length > 0)
        return { ...state, popup: { isOpen: true, content: action.payload } };
      else return { ...state, popup: { isOpen: false, content: null } };
    }
    case 'closePopup': {
      return { ...state, popup: { isOpen: false, content: null } };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const MapContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(mapReducer, {
    map: null,
    mapView: null,
    mapLayers: [],
    loaded: false, //map loaded
    ready: false, //layerviews ready
    popup: { isOpen: false, content: null },
  });

  const getLayer = (title) => {
    if (!state.loaded) {
      return null;
    }

    const layer = state.mapLayers.find((l) => l.title === title);
    if (layer?.type === 'group') {
      return layer.layers.items;
    }

    return layer;
  };

  const getLayerView = (title) => {
    if (!state.ready) {
      return null;
    }

    const layerView = state.mapView.layerViews.items.find(
      (lv) => lv.layer.title === title
    );

    if (layerView?.type === 'group') {
      return layerView.layerViews.items;
    }
    return layerView;
  };

  React.useEffect(() => {
    const setReady = async () => {
      await Promise.all(
        state.mapLayers.map(async (l) => {
          const layerView = await state.mapView.whenLayerView(l);
          await watchUtils.whenFalseOnce(layerView, 'updating');
          console.log(`layerView ${l.title} is done loading and drawing.`);
          return null;
        })
      );

      dispatch({ type: 'setReady', payload: true });
    };
    if (state.loaded) {
      setReady();
    }
  }, [state.loaded]);

  React.useEffect(() => {
    if (state.ready) {
      const handle = watchUtils.whenTrue(state.mapView, 'stationary', () => {
        const compass = state.mapWidgets['compass'];
        if (state.mapView.rotation === 0) {
          state.mapView.ui.remove(compass);
        } else {
          state.mapView.ui.add(compass, 'top-right');
        }
      });

      return () => {
        handle?.remove();
      };
    }
  }, [state.ready]);

  const value = { state, dispatch, getLayer, getLayerView };
  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export const useMapContext = () => {
  const context = React.useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMapContext must be used within a MapContextProvider');
  }
  return context;
};
