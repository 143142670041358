// Framework and third-party non-ui
import * as React from 'react';
import Graphic from '@arcgis/core/Graphic';
import {
  getAdjacentFloodFeatures,
  getFloodFeature,
  savePin2FloodPolygon,
  savePindrop,
} from './utilities';
import { getSymbolUrl } from 'utils/urls';

// Hooks, context, and constants

// App components
import { DropPinButton } from './DropPinButton';
import { DropPinOptions } from './DropPinOptions';
import { DropPinResults } from './DropPinResult';

// JSON & Styles
import { StyledContainer } from './DropPinWidget-styled';
import { useAuthContext, useMapContext } from 'contexts';

// Third-party components (buttons, icons, etc.)
export const usePinDropCreator = ({
  mapView,
  user,
  floodLayer,
  floodFields,
  pinDropLayers,
  pinDropFields,
  pin2FloodLayer,
  pin2FloodFields,
}) => {
  // State and Constants
  const { SymbolsConfig } = window.Pin2Flood;
  const [mapPoint, setMapPoint] = React.useState(null);
  const [candidate, setCandidate] = React.useState(null);
  const [candidateFlood, setCandidateFlood] = React.useState(null);
  const [adjacentFloods, setAdjacentFloods] = React.useState(null);

  const [save, setSave] = React.useState({
    ready: true,
    status: null,
    message: '',
  });
  const [location, setLocation] = React.useState('start');

  // Actions

  const handleUpdateMapPoint = (point) => {
    setMapPoint({ ...mapPoint, ...point });
  };

  const handleUpdateCandidate = async (point) => {
    if (candidate) {
      setCandidate({ ...candidate, ...point });
    } else {
      const existingPinDrop = null; //await queryPindropLayer(point);

      const pinDrop = existingPinDrop
        ? existingPinDrop
        : {
            geometry: point.geometry,
            ObjectId: undefined,
            pinType: point.pinType,
          };

      setCandidate(pinDrop);
    }

    const floodFeature = await getFloodFeature({
      pinDropGeometry: point.geometry,
      fieldNameForHid: floodFields[0]['name'],
      floodLayer: floodLayer,
    });

    const adjacentFeatures = await getAdjacentFloodFeatures({
      flood: floodFeature,
      floodLayer: floodLayer,
    });

    setCandidateFlood(floodFeature);
    setAdjacentFloods(adjacentFeatures);
  };

  const handleMapClick = () => {
    const handler = mapView.on('click', (evt) => {
      setMapPoint({ geometry: evt.mapPoint });
    });

    return handler;
  };

  const handleClearGraphics = () => {
    mapView.graphics.removeAll();
  };

  const handleUpdateCandidateGraphic = () => {
    handleClearGraphics();

    try {
      const { geometry, pinType } = candidate || {};

      if (geometry) {
        const candidateGraphic = new Graphic({
          geometry,
          symbol: {
            type: 'picture-marker',
            url: getSymbolUrl(`pin-dark-${pinType}-owner`),
            height: 15,
            width: 15,
          },
        });

        mapView.graphics.add(candidateGraphic);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddFloodGraphics = () => {
    try {
      const adjacentFloodGraphics = adjacentFloods.map((flood) => {
        return new Graphic({
          geometry: flood.geometry,
          symbol: {
            type: 'simple-fill',
            color: [
              ...SymbolsConfig.colors[`${candidate.pinType}Fill`].slice(0, 3),
              0.05,
            ],
            outline: {
              color: SymbolsConfig.colors[`${candidate.pinType}Outline`],
              width: 1,
            },
          },
        });
      });
      const floodGraphic = new Graphic({
        geometry: candidateFlood.geometry,
        symbol: {
          type: 'simple-fill',
          color: SymbolsConfig.colors[`${candidate.pinType}Fill`],
          outline: {
            color: SymbolsConfig.colors[`${candidate.pinType}Outline`],
            width: 1,
          },
        },
      });

      mapView.graphics.addMany(adjacentFloodGraphics);
      mapView.graphics.add(floodGraphic);
    } catch (err) {
      console.error(err);
    }
  };

  const handleReset = () => {
    handleClearGraphics();
    setCandidate(null);
    setAdjacentFloods(null);
    setCandidateFlood(null);
    setMapPoint(null);
    setSave({
      ready: true,
      status: null,
      message: '',
    });
    setLocation('start');
  };

  const handleSavePinDrop = async () => {
    setSave({ status: 'loading', ready: false, message: '' });

    const { geometry, ObjectId, pinType } = candidate || {};
    const curTime = new Date().getTime();

    try {
      const pin2floodPolygon = await getFloodFeature({
        pinDropGeometry: geometry,
        fieldNameForHid: floodFields[0]['name'],
        floodLayer: floodLayer,
      });

      const { id, name } = user;
      const compositeId = pin2floodPolygon?.attributes[floodFields[1]['name']];

      const savePindropResponse = await savePindrop({
        geometry: geometry,
        //these fields from pins
        attributes: {
          userId: id,
          userFullName: name,
          compositeId: compositeId,
          pinDropTime: curTime,
          ObjectId: ObjectId,
          pinType: pinType,
        },
        fields: pinDropFields,
        pinDropLayer: pinDropLayers[0],
      });

      if (pin2floodPolygon) {
        const saveP2FResponse = await savePin2FloodPolygon({
          geometry: pin2floodPolygon.geometry,
          //these fields from pin2flood
          attributes: {
            userId: id,
            userFullName: name,
            compositeId: compositeId,
            pindropId: savePindropResponse.globalId,
            pinDropTime: curTime,
            pinType: pinType,
          },
          pin2FloodLayer: pin2FloodLayer,
          fields: pin2FloodFields,
        });

        const status = saveP2FResponse?.objectId ? 'succeeded' : 'failed';
        console.log(`Saved pin2flood polygon: ${status}`);
      } else {
        console.log('No Flood Polygons found at this location.');
      }

      setSave({ ready: true, status: 'success', message: '' });
      handleClearGraphics();
    } catch (err) {
      console.error(err);
      // TODO: if pindrop is saved but p2f fails we will need to roll back?
      setSave({ ready: true, status: 'failed', message: err });
    }
  };

  // Effects

  React.useEffect(() => {
    if (mapView) {
      handleUpdateCandidateGraphic(candidate);
    }
  }, [candidate]);

  React.useEffect(() => {
    const updateCandidate = () => {
      if (!mapPoint) {
        return;
      }

      handleUpdateCandidate(mapPoint);
    };
    updateCandidate();
  }, [mapPoint]);

  React.useEffect(() => {
    let handler = null;
    if (mapView && location !== 'start') {
      handler = handleMapClick();
    }

    return () => {
      handler?.remove();
    };
  }, [mapView, location]);

  React.useEffect(() => {
    if (candidateFlood && adjacentFloods) {
      handleAddFloodGraphics();
    }
  }, [candidateFlood, adjacentFloods]);

  return {
    save,
    mapPoint,
    candidate,
    handleUpdateMapPoint,
    handleReset,
    handleSavePinDrop,
    location,
    setLocation,
  };
};

export const usePinDropTypes = ({ mapView, pinDropGroupName }) => {
  const groupLV = mapView?.layerViews.items.find(
    (lv) => lv.layer.title === pinDropGroupName
  );
  const items = groupLV?.layerViews.items;

  const types = items?.map((i) => {
    const name = i.layer.title;
    const alias = name.replace(/ *\([^)]*\) */g, '');
    const saveType = name.match(/\(([^)]+)\)/)[1];
    const symbol = i.layer.renderer.defaultSymbol;
    return { name, alias, saveType, symbol };
  });

  return { types: types ? types : [] };
};

export const DropPinWidget = ({
  updatePinDrops,
  onError = () => {},
  onDropPin = () => {},
}) => {
  // State and Constants
  const { LayersConfig } = window.Pin2Flood;

  const MapContext = useMapContext();
  const AuthContext = useAuthContext();

  const { mapView } = MapContext.state;
  const { user } = AuthContext.state;

  const pinDropFields = LayersConfig['PINDROP_POINTS']['fields'];
  const pin2FloodFields = LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['fields'];
  const floodFields = LayersConfig['PINDROP_FLOOD_POLYGONS_TITLE']['fields'];

  const pinDropLayerGroupTitle = LayersConfig['PINDROP_POINTS']['title'];
  const floodLayerTitle = LayersConfig['PINDROP_FLOOD_POLYGONS_TITLE']['title'];
  const pin2FloodLayerTitle = LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['title'];

  const pinDropLayers = MapContext.getLayer(pinDropLayerGroupTitle);
  const floodLayer = MapContext.getLayer(floodLayerTitle);
  const pin2FloodLayer = MapContext.getLayer(pin2FloodLayerTitle);

  const {
    mapPoint,
    handleUpdateMapPoint,
    handleReset,
    handleSavePinDrop,
    save,
    location,
    setLocation,
  } = usePinDropCreator({
    mapView,
    user,
    floodLayer,
    floodFields,
    pinDropLayers,
    pinDropFields,
    pin2FloodLayer,
    pin2FloodFields,
  });

  const options = usePinDropTypes({
    mapView,
    pinDropGroupName: pinDropLayerGroupTitle,
  });

  // Actions
  const onSaveComplete = async () => {
    // update pin features
    updatePinDrops();

    //refresh layers
    for (const layer of pinDropLayers) {
      layer.refresh();
    }

    handleReset();
  };

  const onRestart = () => {
    handleReset();
  };

  // Effects
  React.useEffect(() => {
    onDropPin({ isDroppingPin: location !== 'start' });
  }, [location]);

  return (
    <StyledContainer id="drop-pin-widget">
      {location === 'start' && <DropPinButton setView={setLocation} />}
      {location === 'active' && (
        <DropPinOptions
          setView={setLocation}
          updatePinPoint={handleUpdateMapPoint}
          pinPoint={mapPoint}
          options={options.types}
        />
      )}
      {location === 'options' && (
        <DropPinResults
          onRestart={onRestart}
          onSaveComplete={onSaveComplete}
          onError={onError}
          handleSavePinDrop={handleSavePinDrop}
          saveStatus={save}
        />
      )}
    </StyledContainer>
  );
};

// 4127205

// 4254105
