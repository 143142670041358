// Framework and third-party non-ui
import * as React from 'react';
import ReactDOM from 'react-dom';
// import reportWebVitals from './reportWebVitals';
import esriConfig from '@arcgis/core/config';
import esriRequest from '@arcgis/core/request';

// Hooks, context, and constants
import { MapContextProvider, AuthContextProvider } from 'contexts';

// App components
import App from 'components/App';

// JSON & Styles
import './index.css';
import '@esri/calcite-components/dist/calcite/calcite.css';
import { setAssetPath } from '@esri/calcite-components/dist/components';

// CDN hosted assets
setAssetPath('https://js.arcgis.com/calcite-components/1.0.0-beta.80/assets');

const fetchConfig = async () => {
  try {
    // Fetch runtime configurations and add to the global "Pin2Flood" namespace
    const { data } = await esriRequest('config/config.json');
    window.Pin2Flood = data;

    // Set Portal URL for the JSAPI
    if (window.Pin2Flood.AppConfig.portalUrl) {
      esriConfig.portalUrl = window.Pin2Flood.AppConfig.portalUrl;
    }

    return;
  } catch (error) {
    console.error(
      'Error loading runtime configurations from config/config.json',
      error
    );
  }
};

const init = async () => {
  await fetchConfig();

  ReactDOM.render(
    <React.StrictMode>
      <AuthContextProvider>
        <MapContextProvider>
          <App />
        </MapContextProvider>
      </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
init();
