import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: 5px;
`;

export const StyledLoadElement = styled(motion.span)`
  display: block;
  width: 20px;
  height: 20px;
  border: 3px solid #8a8a8a;
  border-top: 3px solid #096bc8;
  border-right: 3px solid #096bc8;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
`;

export const StyledLoadElementSecondary = styled(motion.span)`
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border: transparent;
  border-bottom: 0.25rem solid #e9e9e9;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
`;
