// Framework and third-party non-ui
import * as React from 'react';

// Hooks, context, and constants

// App components

// JSON & Styles
import { StyledBackDrop } from './Popup-styled';

// Third-party components (buttons, icons, etc.)

export const BackDrop = ({ children, onClick }) => {
  // State and Constants

  // Actions

  // Effects

  return (
    <StyledBackDrop
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </StyledBackDrop>
  );
};
