import styled from 'styled-components';
import { motion } from 'framer-motion';

export const DrawerWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  bottom: 0;
  pointer-events: none;
  z-index: 1;
`;

export const DrawerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ overlayColor }) => overlayColor};
  pointer-events: all;
  z-index: 0;
  pointer-events: none;
`;

export const DrawerContent = styled(motion.div)`
  position: relative;
  z-index: 1;
  pointer-events: all;
  background-color: #fff;
  width: 100%;
  height: ${({ height, padding }) => `${height + padding}px`};
  padding-bottom: ${({ padding }) => `${padding}px`};
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
    0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DrawerControl = styled(motion.button)`
  border: none;
  background: transparent;
  border-radius: 0;
  outline: none;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const DrawerTopHover = styled(motion.div)`
  border: none;
  background: transparent;
  border-radius: 0;
  outline: none;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const RectangleIcon = styled.div`
  width: 50px;
  height: 5px;
  background: #4c4c4c;
  border-radius: 8px;
  margin: 20px 0px;
`;

export const StyledTitleContainer = styled.div`
  height: 52px;
  display: flex;
  border-bottom: 1px solid rgb(234, 234, 234);
  width: 100%;
`;

export const StyledTitle = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0.775rem 1.55rem;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4c4c4c;
`;

export const StyledContentContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: auto;
  white-space: nowrap;
`;

export const StyledContentRow = styled.div`
  padding: 10px;
`;
export const StyledContentRowTitle = styled.div`
  font-weight: 600;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4c4c4c;
`;

export const StyledContentRowContent = styled.div``;

export const StyledButtonGroup = styled.div`
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    padding: 1rem;
}`;

export const StyledButton = styled.button`
  align-items: center;
  padding: 12px 16px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0;
  border-radius: 4px;
  border: 1px solid #096bc8;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #096bc8;
  &:hover {
    background: #eaeaea;
  }
`;

export const StyledCloseButton = styled.button`
  padding: 1.03333rem;
  position: absolute;
  right: 0;
  width: auto;
  background: transparent;
  white-space: nowrap;
  background: transparent;
  border: none;
  color: rgb(128, 128, 128);
  text-align: initial;
  height: 52px;
  &:hover {
    background: #eaeaea;
  }
`;
