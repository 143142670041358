import styled from 'styled-components';

export const StyledContainer = styled.div`
  z-index: 10000;
  position: fixed;
  height: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: end;
`;

export const StyledInfoLabel = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
`;

export const StyledButton = styled.button`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  height: 48px;

  background: #096bc8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  border: none;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
`;

export const StyledLRButton = styled.button`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  position: static;
  height: 48px;

  background: ${({ selected, disabled }) =>
    selected ? (disabled ? '#8a8a8a' : '#096bc8') : '#ffffff'};
  border-radius: ${({ position }) =>
    position === 'left'
      ? '8px 0px 0px 8px'
      : position === 'right'
      ? '0px 8px 8px 0px'
      : '0px 0px 0px 0px'};
  border: none;
  border-left: ${({ position }) =>
    position === 'middle' ? '1px solid #dfdfdf' : 'none'};
  border-right: ${({ position }) =>
    position === 'middle' ? '1px solid #dfdfdf' : 'none'};

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: ${({ selected, disabled }) =>
    selected ? '#ffffff' : disabled ? '#8a8a8a' : '#096bc8'};
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  padding: 0.5rem;
  align-content: center;
  justify-content: center;
`;

export const StyledContentContainer = styled.div`
  padding: 1rem;
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  align-content: center;
  justify-content: center;
`;
