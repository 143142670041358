// Framework and third-party non-ui
import * as React from 'react';

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  LayerCardContainer,
  LayerCardImage,
  LayerCardLabel,
} from './Sidebar-styled';

// Third-party components (buttons, icons, etc.)

export const LayerCard = ({ layers, onSelect }) => {
  if (!layers) return 'loading';

  return (
    <>
      {Object.keys(layers).map((i) => {
        const layer = layers[i];
        return (
          <LayerCardContainer onClick={() => onSelect(layer)} key={layer.id}>
            <LayerCardImage
              src={layer.thumbnailUrl}
              selected={layer.selected}
            />
            <LayerCardLabel>{layer.label}</LayerCardLabel>
          </LayerCardContainer>
        );
      })}
    </>
  );
};
