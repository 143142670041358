import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: white;
  height: auto;

  width: auto;
`;

export const H3 = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #4c4c4c;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 0px;
  padding: 0 0.5rem;
`;
