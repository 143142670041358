// Framework and third-party non-ui
import * as React from 'react';

import { AnimatePresence } from 'framer-motion';

// Hooks, context, and constants
import { useMapContext, useAuthContext } from 'contexts';

// App components
import Map from 'components/Map';
import { Sidebar } from 'components/Sidebar';
// import { AddressTile } from 'components/Tile';
import { Drawer, FeatureDrawer } from 'components/Drawer';
import { LayerListContainer } from 'components/Sidebar/LayerListContainer';
import { BasemapGalleryContainer } from 'components/Sidebar/BasemapGalleryContainer';
import { Popup } from 'components/Popup';

import { Pin2FloodWidget } from 'components/Pin2FloodWidget';
import { DropPinWidget } from 'components/DropPinWidget';
import { Feedback } from 'components/Feedback';

// JSON & Styles
import './App.css';
import { AppWrapper, MapWrapper } from './App-styled';

// Third-party components (buttons, icons, etc.)

const usePinDropFeatures = ({ mapView = null, layers = [] }) => {
  const { LayersConfig } = window.Pin2Flood;

  const [addressCount, setAddressCount] = React.useState(0);
  const [pinDropFeatures, setPinDropFeatures] = React.useState([]);

  const p2fLayerConfig = LayersConfig['PIN2FLOOD_POLYGONS_TITLE'];
  const floodLayerConfig = LayersConfig['PINDROP_FLOOD_POLYGONS_TITLE'];

  const getPinDropFeatures = async () => {
    const pinDropConfig = LayersConfig['PINDROP_POINTS'];

    const fieldNameForUserId = pinDropConfig['fields'][0]['name'];
    const fieldNameForCompositeId = pinDropConfig['fields'][3]['name'];

    let featureItems = [];
    for (const layer of layers) {
      const queryParams = layer?.createQuery();
      const { features } = await layer?.queryFeatures(queryParams);

      const items =
        features && features.length
          ? features
              .map((feature) => {
                return {
                  userId: feature.attributes[fieldNameForUserId],
                  compositeId: feature.attributes[fieldNameForCompositeId],
                };
              })
              .filter((d) => d.compositeId) // handle pins without compositeID
          : [];
      featureItems = [...featureItems, ...items];
    }

    return featureItems;
  };

  const getTotalAddressCount = async () => {
    const p2fLayer = mapView.map.layers.items.find(
      (layer) => layer.title === p2fLayerConfig['title']
    );
    const floodLayer = mapView.map.layers.items.find(
      (layer) => layer.title === floodLayerConfig['title']
    );

    const p2fLV = mapView.layerViews.items.find(
      ({ layer }) => layer.title === p2fLayerConfig['title']
    );

    if (!p2fLayer.definitionExpression) {
      return 0;
    }

    const fieldNameForAddress = floodLayerConfig['fields'][3]['name'];

    const stats = [
      {
        onStatisticField: fieldNameForAddress,
        outStatisticFieldName: 'total_address_count',
        statisticType: 'sum',
      },
    ];
    let statsQuery = floodLayer.createQuery();
    statsQuery.where = p2fLayer.definitionExpression;
    statsQuery.outStatistics = stats;

    const response = await floodLayer.queryFeatures(statsQuery);
    const { total_address_count } = response.features[0]?.attributes || {};

    return total_address_count ? total_address_count : 0;
  };

  const updatePinDrops = async () => {
    const features = await getPinDropFeatures();
    if (features) setPinDropFeatures(features);
  };

  React.useEffect(() => {
    const updateAddressCount = async () => {
      if (mapView && layers) {
        const count = await getTotalAddressCount();
        setAddressCount(count);
      }
    };

    updateAddressCount();
    // update address count
  }, [pinDropFeatures]);

  return { addressCount, pinDropFeatures, updatePinDrops };
};

const App = () => {
  // State and Constants
  const { LayersConfig } = window.Pin2Flood;
  const MapContext = useMapContext();
  const AuthContext = useAuthContext();
  const { mapView, ready, popup } = MapContext.state;
  const { user } = AuthContext.state;

  const pinDropLayerGroupTitle = LayersConfig['PINDROP_POINTS']['title'];
  const pinDropLayers = MapContext.getLayer(pinDropLayerGroupTitle);

  const { pinDropFeatures, updatePinDrops } = usePinDropFeatures({
    mapView,
    layers: pinDropLayers,
  });

  const [popupState, setPopupState] = React.useState({ isOpen: false });
  const [, setIsTile] = React.useState(true);

  // Actions
  const onError = () => {
    setPopupState({
      isOpen: true,
      title: 'Error dropping pin.',
      content:
        'An error occurred while dropping your pin. Please check your network connection and try again.',
    });
    return true;
  };

  const onDropPin = ({ isDroppingPin }) => {
    setIsTile(!isDroppingPin);
  };

  // Effects
  React.useEffect(() => {
    const init = () => {
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);
      const customAppId = searchParams.get('appId');
      const { AppConfig } = window.Pin2Flood;

      AuthContext.handleSignIn({
        appId: customAppId || AppConfig['appId'],
        portalUrl: AppConfig['portalUrl'],
      });
    };

    init();
  }, []);

  return (
    <AppWrapper>
      <header />
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {popupState.isOpen && (
          <Popup
            title={popupState.title}
            content={popupState.content}
            onClose={() => {
              setPopupState({ isOpen: false });
            }}
          />
        )}
      </AnimatePresence>

      {user.id && (
        <>
          <MapWrapper>
            <Sidebar>
              <BasemapGalleryContainer mapView={mapView} />
              <LayerListContainer mapView={mapView} />
              <Feedback />
            </Sidebar>
            {/* {isTile && addressCount > 0 && <AddressTile count={addressCount} />} */}
            <Map user={user} />
          </MapWrapper>

          <Drawer
            topHover={
              <DropPinWidget
                updatePinDrops={updatePinDrops}
                onError={onError}
                onDropPin={onDropPin}
              />
            }
          >
            {mapView && ready && (
              <Pin2FloodWidget
                pinDropFeatures={pinDropFeatures}
                updatePinDrops={updatePinDrops}
              />
            )}
          </Drawer>

          <FeatureDrawer
            user={user}
            mapView={mapView}
            isOpen={popup.isOpen}
            features={popup.content}
            onClose={() => {
              MapContext.dispatch({ type: 'closePopup' });
              updatePinDrops();
            }}
          />
        </>
      )}
    </AppWrapper>
  );
};

export default App;
