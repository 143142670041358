// Framework and third-party non-ui
import * as React from 'react';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';

// Hooks, context, and constants
import { useAuthContext, useMapContext } from 'contexts';

// App components
import { PinsToggleFilter } from './PinsToggleFilter';
import { PredictionsToggleFilter } from './PredictionsToggleFilter';

// JSON & Styles
import { StyledContainer, H3 } from './Pin2FloodWidget-styled';

// Third-party components (buttons, icons, etc.)

export const Pin2FloodWidget = ({ pinDropFeatures, updatePinDrops }) => {
  // State and Constants
  const { LayersConfig } = window.Pin2Flood;

  const MapContext = useMapContext();
  const UserContext = useAuthContext();
  const userData = UserContext.state.user;

  const pinDropLayerGroupTitle = LayersConfig['PINDROP_POINTS']['title'];
  const p2fLayerTitle = LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['title'];
  const floodPredictionsLayerTitle =
    LayersConfig['FLOOD_PREDICTIONS_POLYGONS_TITLE']['title'];

  const pinDropLayers = MapContext.getLayer(pinDropLayerGroupTitle);
  const floodLayer = MapContext.getLayer(p2fLayerTitle);
  const predictionsLayer = MapContext.getLayer(floodPredictionsLayerTitle);

  const pinIntervals = [
    { tInterval: { start: -1, end: 0 }, label: '1 hr' },
    { tInterval: { start: -3, end: 0 }, label: '3 hr' },
    { tInterval: { start: -6, end: 0 }, label: '6 hr' },
    { tInterval: null, label: 'All' },
  ];
  const predictionIntervals = [
    { tInterval: { start: 0, end: 0 }, label: 'Now' },
    { tInterval: { start: 3, end: 3 }, label: '3 hr' },
    { tInterval: { start: 6, end: 6 }, label: '6 hr' },
    { tInterval: { start: 12, end: 12 }, label: '12 hr' },
  ];

  const [pinDropLVs, setPinDropLVs] = React.useState(null);
  const [floodLV, setFloodLV] = React.useState(null);
  const [, setPredictionsLV] = React.useState(null);

  // Actions
  const updateFloodLayer = () => {
    if (!floodLayer?.visible) {
      floodLayer.visible = true;
    }
    const { LayersConfig, SymbolsConfig } = window.Pin2Flood;
    const compositeIds = pinDropFeatures.map((d) => d.compositeId);

    const defaultSymbol = floodLayer.renderer.symbol
      ? floodLayer.renderer.symbol.clone()
      : floodLayer.renderer.defaultSymbol.clone();

    // Symbol for: Owner, flood
    const floodSymbol = defaultSymbol.clone();
    floodSymbol.color = SymbolsConfig.colors.floodFill;
    floodSymbol.outline.color = SymbolsConfig.colors.floodOutline;

    // Symbol for: Owner, preplan
    const preplanSymbol = defaultSymbol.clone();
    preplanSymbol.color = SymbolsConfig.colors.preplanFill;
    preplanSymbol.outline.color = SymbolsConfig.colors.preplanOutline;

    // TODO: Move renderer
    const renderer = new UniqueValueRenderer({
      defaultSymbol,
      defaultLabel: `${LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['title']}`,
      // TODO: refactor `fields` to be a dictionary-style object rather than an array
      field: LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['fields'][5].name, //pintype
      fieldDelimiter: ',',

      uniqueValueInfos: [
        {
          value: 'flood',
          symbol: floodSymbol,
          label: `Flood Edge ${LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['title']}`,
        },
        {
          value: 'preplan',
          symbol: preplanSymbol,
          label: `Pre-Plan ${LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['title']}`,
        },
      ],
      visualVariables: defaultSymbol.visualVariables,
    });

    const defExp = compositeIds.length
      ? `${
          LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['fields'][4].name
        } in (${compositeIds.join(',')})`
      : `${LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['fields'][4].name} = -999`;

    floodLayer.orderBy = {
      field: LayersConfig['PINDROP_FLOOD_POLYGONS_TITLE']['fields'][2].name,
      order: 'ascending',
    };

    floodLayer.renderer = renderer;
    floodLayer.definitionExpression = defExp;
  };

  // Effects
  React.useEffect(() => {
    if (pinDropLVs && floodLV) {
      updateFloodLayer();
    }
  }, [pinDropFeatures, pinDropLVs, floodLV]);

  React.useEffect(() => {
    const setLayers = () => {
      const pinDropLVList = MapContext.getLayerView(
        pinDropLayers[0]?.parent.title
      );
      const pinFloodLV = MapContext.getLayerView(floodLayer?.title);
      const floodPredictionsLV = MapContext.getLayerView(
        predictionsLayer?.title
      );

      setPinDropLVs(pinDropLVList);
      setFloodLV(pinFloodLV);
      setPredictionsLV(floodPredictionsLV);
    };
    if (MapContext.state.ready) {
      setLayers();
    }
  }, [MapContext.state.ready]);

  return (
    <StyledContainer>
      {pinDropLayers && (
        <>
          <H3> Pins Dropped </H3>
          <PinsToggleFilter
            intervals={pinIntervals}
            layers={pinDropLayers}
            updatePinDrops={updatePinDrops}
          />
        </>
      )}

      {predictionsLayer && (
        <>
          <H3> Predictions </H3>
          <PredictionsToggleFilter
            intervals={predictionIntervals}
            layer={predictionsLayer}
          />
        </>
      )}
    </StyledContainer>
  );
};
