import * as React from 'react';

import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import esriId from '@arcgis/core/identity/IdentityManager';
import Portal from '@arcgis/core/portal/Portal';

const AuthContext = React.createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case 'addUser': {
      return { ...state, user: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, {
    user: {},
  });

  const signIn = async (portalUrl) => {
    esriId.getCredential(portalUrl + '/sharing').then((res) => {
      console.log('signed in', res);
    });
  };

  const handleSignIn = async ({
    appId,
    portalUrl = 'https://www.arcgis.com',
  }) => {
    const url = portalUrl ? portalUrl : 'https://www.arcgis.com';
    try {
      const oauthInfo = new OAuthInfo({
        appId: appId,
        portalUrl: url,
        popup: false,
      });

      esriId.useSignInPage = false;
      esriId.registerOAuthInfos([oauthInfo]);

      const credential = await esriId.checkSignInStatus(
        oauthInfo.portalUrl + '/sharing'
      );

      // init paortal
      const userPortal = new Portal({
        url: url,
      });
      // Setting authMode to immediate signs the user in once loaded
      userPortal.authMode = 'immediate';

      // Once loaded, user is signed in
      await userPortal.load();

      const { user, name, urlKey, customBaseUrl } = userPortal;
      const { fullName, username, thumbnailUrl } = user;

      dispatch({
        type: 'addUser',
        payload: {
          credential,
          portal: userPortal,
          name: fullName,
          id: username,
          group: name,
          image: thumbnailUrl,
          customHostUrl: `https://${urlKey}.${customBaseUrl}`,
        },
      });
    } catch (err) {
      console.log(err);
      signIn(url);
    }
  };

  const value = { state, dispatch, handleSignIn };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }
  return context;
};
