import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
  padding: 0.5rem;
  display: flex;
  text-align: center;
`;

export const StyledButton = styled.button`
  /* Frame 152 */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;

  position: static;
  width: 80px;
  height: 38px;

  background: ${({ selected }) => (selected ? '#096bc8' : '#ffffff')};
  border: 1px solid #096bc8;
  border-radius: ${({ borderRadius }) => borderRadius};

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
  margin: 0px 0px;

  /* Font */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: ${({ selected }) => (selected ? '#ffffff' : '#096bc8')}; ;
`;
