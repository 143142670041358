export const basemapCollection = [
  {
    name: 'hybrid',
    label: 'Satellite',
  },
  {
    name: 'streets-navigation-vector',
    label: 'Light',
  },
  {
    name: 'streets-night-vector',
    label: 'Dark',
  },
  {
    name: 'topo-vector',
    label: 'Topo',
  },
  {
    name: 'osm',
    label: 'OSM',
  },
];

export const layerCollection = [
  {
    name: 'event_pins',
    label: 'Event Pins',
    thumbnailUrl: '/images/event-pins.png',
  },
  {
    name: 'planning_pins',
    label: 'Planning Pins',
    thumbnailUrl: '/images/planning-pins.png',
  },
  {
    name: 'flood_polys',
    label: 'Flood Areas',
    thumbnailUrl: '/images/flood-areas.png',
  },
  {
    name: 'forecast_polys',
    label: 'Forecasts',
    thumbnailUrl: '/images/forecast-areas.png',
  },
];
