// Framework and third-party non-ui
import * as React from 'react';

// Hooks, context, and constants

// App components

// JSON & Styles

import {
  StyledInfoLabel,
  StyledLRButton,
  StyledInnerContainer,
  StyledContentContainer,
} from './DropPinWidget-styled';

// Third-party components (buttons, icons, etc.)

export const DropPinOptions = ({
  setView,
  updatePinPoint,
  pinPoint,
  options,
}) => {
  // State and Constants

  // Actions
  const onSelectType = ({ pinType, ...props }) => {
    updatePinPoint({ pinType: pinType, symbol: props.symbol });
  };

  // Effects
  React.useEffect(() => {
    updatePinPoint({ pinType: options ? options[0].saveType : null });
  }, []);

  React.useEffect(() => {
    if (pinPoint?.geometry) {
      setView('options');
    }
  }, [pinPoint]);

  return (
    <StyledContentContainer>
      <StyledInnerContainer>
        <StyledInfoLabel> Drop pin on map </StyledInfoLabel>
      </StyledInnerContainer>

      <StyledInnerContainer>
        {options.map((op, idx) => {
          const pos =
            idx === 0
              ? 'left'
              : idx === options.length - 1
              ? 'right'
              : 'middle';
          return (
            <StyledLRButton
              key={op.name}
              position={pos}
              selected={pinPoint?.pinType === op.saveType}
              onClick={() => {
                onSelectType({ pinType: op.saveType, ...op });
              }}
            >
              {op.alias}
            </StyledLRButton>
          );
        })}
      </StyledInnerContainer>
    </StyledContentContainer>
  );
};
