// Framework and third-party non-ui
import * as React from 'react';

import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';

import { MapUtils } from 'utils';
import { getSymbolUrl } from 'utils/urls';

// Hooks, context, and constants
import { useMapContext } from 'contexts';

// App components

// JSON & Styles
import { StyledMap } from './Map-styled';

// Third-party components (buttons, icons, etc.)

const Map = ({ user }) => {
  // State and Constants
  const mapRef = React.useRef();
  const MapContext = useMapContext();

  // Actions

  // Effects

  React.useEffect(() => {
    const { MapConfig, LayersConfig } = window.Pin2Flood;
    const mapItem = MapUtils.initMap(MapConfig['webmapId']);
    const { view, widgets } = MapUtils.initView(mapItem, mapRef, MapContext);

    view.when(() => {
      const layers = mapItem.layers.items;

      // Set renderer color by user
      const pinDropGroup = layers.find(
        (l) => l.title == LayersConfig['PINDROP_POINTS']['title']
      );

      // TODO: Improve
      for (const l of pinDropGroup.layers.items) {
        // Owner, flood
        const ownerFloodSymbol = l.renderer.symbol.clone();
        ownerFloodSymbol.url = getSymbolUrl('pin-dark-flood-owner');

        // Owner, preplan
        const OwnerPreplanSymbol = l.renderer.symbol.clone();
        OwnerPreplanSymbol.url = getSymbolUrl('pin-dark-preplan-owner');

        const renderer = new UniqueValueRenderer({
          defaultSymbol: l.renderer.symbol,
          defaultLabel: `Non User ${LayersConfig['PINDROP_POINTS']['title']}`,
          // TODO: refactor `fields` to be a dictionary-style object rather than an array
          field: LayersConfig['PINDROP_POINTS']['fields'][0].name, //userid
          field2: LayersConfig['PINDROP_POINTS']['fields'][4].name, //pintype
          fieldDelimiter: ',',

          uniqueValueInfos: [
            {
              value: [user.id, 'flood'].join(','),
              symbol: ownerFloodSymbol,
              label: `Owner Flood Edge ${LayersConfig['PINDROP_POINTS']['title']}`,
            },
            {
              value: [user.id, 'preplan'].join(','),
              symbol: OwnerPreplanSymbol,
              label: `Owner Pre-Plan ${LayersConfig['PINDROP_POINTS']['title']}`,
            },
          ],
          visualVariables: l.renderer.visualVariables,
        });

        l.renderer = renderer;
      }

      view.popup.visible = false;
      view.popup.watch('title', async () => {
        view.popup.visible = false;
        const floodLayer = view.layerViews.items.find(
          (lv) =>
            lv.layer.title ===
            LayersConfig['PINDROP_FLOOD_POLYGONS_TITLE']['title']
        ).layer;

        const calculatePopupContent = async (feature, floodLayer) => {
          const compositeid =
            feature.attributes[
              LayersConfig['PINDROP_POINTS']['fields'][3]['name']
            ];
          const params = {
            where: `${LayersConfig['PINDROP_FLOOD_POLYGONS_TITLE']['fields'][1]['name']}=${compositeid}`,
            returnGeometry: true,
            outFields: [
              LayersConfig['PINDROP_FLOOD_POLYGONS_TITLE']['fields'][3]['name'],
            ],
            f: 'json',
          };
          let query = floodLayer.createQuery();
          query = { ...query, ...params };

          const response = await floodLayer.queryFeatures(query);
          const floodFeature =
            response?.features?.length > 0 ? response.features[0] : null;

          return {
            title: 'Details',
            feature,
            flood: floodFeature,
            floodExtent: floodFeature.geometry.extent,
            addressCount:
              floodFeature.attributes[
                LayersConfig['PINDROP_FLOOD_POLYGONS_TITLE']['fields'][3][
                  'name'
                ]
              ], // TODO: Parameterize
          };
        };

        if (view.popup.features) {
          const popupContent = await Promise.all(
            view.popup.features
              .filter(
                (f) => f.attributes.pintype !== null && f.geometry !== null
              )
              .map((feature) =>
                calculatePopupContent(feature, floodLayer, view.popup.title)
              )
          );

          MapContext.dispatch({ type: 'openPopup', payload: popupContent });
        }
      });

      MapContext.dispatch({
        type: 'setMapView',
        payload: { view, layers, widgets },
      });
    });
  }, []);

  return (
    <>
      <StyledMap ref={mapRef} data-testid="WebMap" />
    </>
  );
};

export default Map;
