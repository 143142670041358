// Framework and third-party non-ui
import * as React from 'react';

import { getAddSubHour, formatDateTime } from './utils';

// Hooks, context, and constants

// App components

// JSON & Styles
import { StyledButton, StyledButtonGroup } from './ArcHydroComponents-styled';

// Third-party components (buttons, icons, etc.)

export const ToggleTimeFilter = ({
  intervals = [],
  layers = [],
  onChange = () => {},
  round = false,
  timeField = null,
}) => {
  // State and Constants
  const [selectedTInterval, setSelectedTInterval] = React.useState(
    intervals.length > 0 ? intervals[0].tInterval : null
  );

  // Actions
  const handleButtonClick = (tInterval) => {
    setSelectedTInterval(tInterval);
    handleSetLayerTInterval(tInterval);
  };

  const handleSetLayerTInterval = (tInterval) => {
    if (layers?.length < 1) {
      return;
    }

    let timeExtent = null;
    let defExpTime = null;

    if (tInterval) {
      const startTime = getAddSubHour({
        hoursToModify: tInterval.start,
        round,
      });
      const endTime = getAddSubHour({ hoursToModify: tInterval.end, round });

      timeExtent = {
        start: startTime,
        end: endTime,
      };

      // querytime BETWEEN CURRENT_TIMESTAMP - 0.041666666666666664 AND CURRENT_TIMESTAMP

      defExpTime = timeField
        ? `${timeField} BETWEEN CURRENT_TIMESTAMP ${
            tInterval.start > 0 ? '+' : '-'
          } INTERVAL '${Math.abs(tInterval.start)}' HOUR AND CURRENT_TIMESTAMP`
        : null;
    }

    layers.forEach((layer) => {
      if (timeField) {
        let initDefExp = '';
        if (layer.definitionExpression.indexOf(` AND ${timeField}`)) {
          initDefExp = layer.definitionExpression.split(` AND ${timeField}`)[0];
        } else if (layer.definitionExpression.indexOf(`${timeField}`)) {
          initDefExp = layer.definitionExpression.split(timeField)[0];
        }
        layer.definitionExpression = defExpTime
          ? `${initDefExp} AND ${defExpTime}`
          : `${initDefExp}`;
      } else {
        layer.timeExtent = timeExtent;
      }

      if (!layer.visible) {
        layer.visible = true;
      }
    });

    onChange();
  };

  // Effects
  React.useEffect(() => {
    const init = () => {
      if (layers?.length && selectedTInterval) {
        handleSetLayerTInterval(selectedTInterval);
      }
    };

    init();
  }, []);

  return (
    <StyledButtonGroup aria-label="Predictions Toggle Filter" isToggle>
      {intervals.map(({ label, tInterval }, i) => {
        const borderRadius =
          i === 0
            ? '8px 0px 0px 8px'
            : i === intervals.length - 1
            ? '0px 8px 8px 0px'
            : '0px';

        return (
          <StyledButton
            key={`toggle-btn-${i}`}
            variant="secondary"
            selected={
              selectedTInterval?.start === tInterval?.start &&
              selectedTInterval?.end === tInterval?.end
            }
            onClick={() => {
              handleButtonClick(tInterval);
            }}
            borderRadius={borderRadius}
          >
            {label}
          </StyledButton>
        );
      })}
    </StyledButtonGroup>
  );
};
