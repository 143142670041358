// Framework and third-party non-ui
import * as React from 'react';
import { useAnimation } from 'framer-motion';

// Hooks, context, and constants
import { useMapContext } from 'contexts';

// App components

// JSON & Styles
import {
  DrawerWrapper,
  DrawerOverlay,
  DrawerContent,
  StyledTitle,
  StyledTitleContainer,
  StyledContentContainer,
  StyledButtonGroup,
  StyledButton,
  StyledCloseButton,
  StyledContentRow,
  StyledContentRowTitle,
  StyledContentRowContent,
} from './Drawer-styled';

// Third-party components (buttons, icons, etc.)
const useCoordFormatter = ({ point }) => {
  if (point) {
    const degree = {
      lat: Math.abs(point.latitude),
      long: Math.abs(point.longitude),
    };

    const minute = {
      lat: (degree.lat % 1) * 60,
      long: (degree.long % 1) * 60,
    };

    const second = {
      lat: (minute.lat % 1) * 60,
      long: (minute.long % 1) * 60,
    };

    const direction = {
      lat: point.latitude >= 0 ? 'N' : 'S',
      long: point.longitude >= 0 ? 'E' : 'W',
    };

    const dd = `${degree.lat.toFixed(5)}\xB0 ${
      direction.lat
    } ${degree.long.toFixed(5)}\xB0 ${direction.long}`;

    const ddm = `${Math.trunc(degree.lat)}\xB0 ${minute.lat.toFixed(3)}' ${
      direction.lat
    } ${Math.trunc(degree.long)}\xB0 ${minute.long.toFixed(3)}' ${
      direction.long
    }`;

    const dms = `${Math.trunc(degree.lat)}\xB0 ${Math.trunc(
      minute.lat
    )}' ${second.lat.toFixed(1)}" ${direction.lat} ${Math.trunc(
      degree.long
    )}\xB0 ${Math.trunc(minute.long)}' ${second.long.toFixed(1)}" ${
      direction.long
    }`;

    return { dd, ddm, dms };
  }

  return {
    dd: null,
    ddm: null,
    dms: null,
  };
};

const getPinName = (layer) => {
  const { title } = layer || {};
  const name = title;
  const alias = name?.replace(/ *\([^)]*\) */g, '');
  const saveType = name?.match(/\(([^)]+)\)/)[1];

  return { name, alias, type: saveType };
};

export const FeatureDrawer = ({ isOpen, features, onClose, mapView, user }) => {
  // State and Constants
  const height = 400;
  const padding = 100;
  const overlayColor = 'transparent';

  const { LayersConfig } = window.Pin2Flood;

  const popupItem = features?.length > 0 ? features[0] : null;
  const title = popupItem?.title;
  const pinName = getPinName(popupItem?.feature.layer);

  const content = {
    addressCount: popupItem?.addressCount,
    pintype: pinName?.alias,
    geometry: popupItem?.feature.geometry,
    feature: popupItem?.feature,
    userId:
      popupItem?.feature.attributes[
        LayersConfig['PINDROP_POINTS']['fields'][0]['name']
      ],
    userName:
      popupItem?.feature.attributes[
        LayersConfig['PINDROP_POINTS']['fields'][1]['name']
      ],
  };

  const MapContext = useMapContext();
  const pinsLayerGroup = MapContext.getLayer(
    LayersConfig['PINDROP_POINTS']['title']
  );
  const p2fLayer = MapContext.getLayer(
    LayersConfig['PIN2FLOOD_POLYGONS_TITLE']['title']
  );
  const pinsLayer = pinsLayerGroup ? pinsLayerGroup[0]?.source?.layer : null;

  const { dms } = useCoordFormatter({ point: content.geometry });

  const controls = useAnimation();

  const drawerContentStyles = {
    active: {
      y: 0,
    },
    inactive: {
      y: height + padding,
    },
  };

  // Actions
  const handleDeletePin = async () => {
    const id =
      content.feature?.attributes[
        LayersConfig['PINDROP_POINTS'].fields[5]['name']
      ];
    const params = {
      where: `${LayersConfig['PIN2FLOOD_POLYGONS_TITLE'].fields[2]['name']}='${id}'`,
      returnGeometry: false,
      outFields: [LayersConfig['PIN2FLOOD_POLYGONS_TITLE'].fields[6]['name']],
      f: 'json',
    };

    let query = p2fLayer.createQuery();
    query = { ...query, ...params };
    const response = await p2fLayer.queryFeatures(query);
    const p2fFeature = response.features[0];

    try {
      const deletePoint = await pinsLayer.applyEdits({
        deleteFeatures: [content.feature],
      });
      const deletePoly = await p2fLayer.applyEdits({
        deleteFeatures: [p2fFeature],
      });

      // TODO: Error handling

      console.log(
        `Pin Point deleted with status: ${
          deletePoint.deleteFeatureResults[0].error ? 'Failed' : 'Success'
        }`
      );
      console.log(
        `Flood Polygon deleted with status: ${
          deletePoly.deleteFeatureResults[0].error ? 'Failed' : 'Success'
        }`
      );

      pinsLayer.refresh();
      p2fLayer.refresh();
      onClose();
    } catch (err) {
      console.error(err, deletePoint, deletePoly);
    }
  };

  const handleCloseDrawer = () => {
    onClose();
  };

  // Effects
  React.useEffect(() => {
    // play open and close animation when not in partial state
    controls.start(isOpen ? 'active' : 'inactive');
  }, [isOpen, controls]);

  React.useEffect(() => {
    if (isOpen && popupItem) {
      mapView.extent = popupItem.flood.geometry.extent;
      mapView.padding.bottom = height;
    }
  }, [isOpen, features]);

  return (
    <DrawerWrapper height={height}>
      <DrawerOverlay overlayColor={overlayColor} />
      <DrawerContent
        id="feature-popup"
        height={height}
        padding={padding}
        drag="y"
        dragElastic={0.1}
        dragConstraints={{
          top: 0, //window.innerHeight - height,
          bottom: height, //window.innerHeight,
        }}
        dragMomentum={false}
        onDragEnd={(_event, info) => {
          const isDraggingDown = info.offset.y > 0;

          if (isDraggingDown) {
            handleCloseDrawer();
          }
        }}
        animate={controls}
        variants={drawerContentStyles}
        transition={{ type: 'spring', damping: 30, stiffness: 180 }}
      >
        <StyledTitleContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledCloseButton onClick={handleCloseDrawer}>
            <div className="esri-icon-close" />
          </StyledCloseButton>
        </StyledTitleContainer>

        <StyledContentContainer>
          <StyledContentRow>
            <StyledContentRowTitle>
              Addresses in this Polygon
            </StyledContentRowTitle>
            <StyledContentRowContent>
              {content.addressCount}
            </StyledContentRowContent>
          </StyledContentRow>

          <StyledContentRow>
            <StyledContentRowTitle>Pin Coordinates</StyledContentRowTitle>
            <StyledContentRowContent>{dms}</StyledContentRowContent>
          </StyledContentRow>

          <StyledContentRow>
            <StyledContentRowTitle>Pin Type</StyledContentRowTitle>
            <StyledContentRowContent>{content.pintype}</StyledContentRowContent>
          </StyledContentRow>

          <StyledContentRow>
            <StyledContentRowTitle> Submitted By</StyledContentRowTitle>
            <StyledContentRowContent>
              {content.userName} ({content.userId})
            </StyledContentRowContent>
          </StyledContentRow>
        </StyledContentContainer>

        <StyledButtonGroup>
          {content.userId === user.id && (
            <StyledButton onClick={handleDeletePin}> Delete </StyledButton>
          )}
        </StyledButtonGroup>
      </DrawerContent>
    </DrawerWrapper>
  );
};
