import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledBackDrop = styled(motion.div)`
  background: rgba(0, 0, 0, 0.64);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  z-index: 9;
`;

export const StyledContainer = styled(motion.div)`
  width: clamp(50%, 300px, 90%);
  height: min(50%, 250px);

  margin: auto;
  padding: 0 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgb(255, 255, 255);
`;

export const StyledTitleContainer = styled.div`
  border-bottom: 1px solid rgb(234, 234, 234);
  text-align: center;
  font-weight: 500;
  padding: 1rem 0 1rem 0;
  width: 100%;
  font-size: 1.5rem;
`;

export const StyledContentContainer = styled.div`
  line-height: 125%;
  font-weight: 300;
  padding: 1rem;
  margin: 0;
  width: 100%;
  font-size: 1rem;
`;

export const StyledControlContainer = styled.div`
  border-top: 1px solid rgb(234, 234, 234);
  position: relative;
  bottom: 0;
  padding: 1rem 0 1rem 0;
  margin: auto 0 0 0;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}
`;

export const StyledButton = styled(motion.button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  height: 48px;

  background: #096bc8;

  border-radius: 8px;
  border: none;

  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 125%;

  color: #ffffff;
`;
