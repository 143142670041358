// Framework and third-party non-ui
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

import {
  Container,
  SidebarContainer,
  ToggleContainer,
  SidebarContent,
  CloseContainer,
} from './Sidebar-styled';

const sidebarStyles = {
  active: {
    x: -320,
  },
  inactive: {
    x: 0,
  },
};

const ToggleButton = ({ isOpen, setIsOpen }) => {
  return (
    <ToggleContainer
      onTap={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div
        aria-hidden="true"
        className="esri-collapse__icon esri-icon-expand"
      ></div>
    </ToggleContainer>
  );
};

const CloseButton = ({ isOpen, setIsOpen }) => {
  return (
    <CloseContainer
      onTap={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div
        style={{ fontSize: '24px' }}
        aria-hidden="true"
        className="esri-icon-collapse"
      ></div>
    </CloseContainer>
  );
};

const Sidebar = ({ width = 320, color = '#fff', children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const controls = useAnimation();

  // Effects
  React.useEffect(() => {
    // play open and close animation when not in partial state
    controls.start(isOpen ? 'active' : 'inactive');
  }, [isOpen, controls]);

  return (
    <Container>
      <ToggleButton isOpen={isOpen} setIsOpen={setIsOpen} />
      <SidebarContainer
        color={color}
        width={width}
        $isopen={isOpen}
        animate={controls}
        variants={sidebarStyles}
        transition={{ type: 'spring', damping: 90, stiffness: 900 }}
      >
        <CloseButton isOpen={isOpen} setIsOpen={setIsOpen} />
        <SidebarContent>{children}</SidebarContent>
      </SidebarContainer>
    </Container>
  );
};

export { Sidebar };
