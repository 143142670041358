// Framework and third-party non-ui
import * as React from 'react';

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  StyledButton,
  StyledContentContainer,
  StyledInnerContainer,
} from './DropPinWidget-styled';
import MapPinIcon from 'images/map-pin.svg';

// Third-party components (buttons, icons, etc.)

// const pinIcon = (
//   <div
//     style={{ fontSize: '20px', paddingRight: '5px' }}
//     aria-hidden="true"
//     class="esri-icon-map-pin"
//   ></div>
// );

export const DropPinButton = ({ setView }) => {
  // State and Constants

  // Actions
  const handleStartPinDrop = () => {
    setView('active');
  };

  // Effects

  return (
    <StyledContentContainer>
      <StyledInnerContainer>
        <StyledButton onClick={handleStartPinDrop}>
          {/* TODO: Replace Icon */}
          <img
            src={MapPinIcon}
            alt="map pin icon"
            style={{ height: '24px', paddingRight: '5px' }}
          />
          Drop Pin
        </StyledButton>
      </StyledInnerContainer>
    </StyledContentContainer>
  );
};
