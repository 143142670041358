// Framework and third-party non-ui
import * as React from 'react';
import { motion } from 'framer-motion';

// Hooks, context, and constants

// App components
import { BackDrop } from './BackDrop';

// JSON & Styles
import {
  StyledContainer,
  StyledTitleContainer,
  StyledContentContainer,
  StyledControlContainer,
  StyledButton,
} from './Popup-styled';

// Third-party components (buttons, icons, etc.)

const DefaultButton = ({ onClick, label }) => {
  return (
    <StyledButton type="button" whileTap={{ scale: 0.95 }} onClick={onClick}>
      {label}
    </StyledButton>
  );
};

export const Popup = ({ title, content, controls, onClose = () => {} }) => {
  // State and Constants

  const dropIn = {
    hidden: {
      y: '-100vh',
      opacity: 0,
    },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.1,
        type: 'spring',
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: '100vh',
      opacity: 0,
    },
  };

  // Actions
  const handleClose = () => {
    onClose();
  };

  // Effects

  return (
    <BackDrop onClick={handleClose}>
      <StyledContainer
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <StyledTitleContainer> {title} </StyledTitleContainer>

        <StyledContentContainer> {content} </StyledContentContainer>

        <StyledControlContainer>
          {controls ? (
            controls
          ) : (
            <DefaultButton label="Close" onClick={handleClose} />
          )}
        </StyledControlContainer>
      </StyledContainer>
    </BackDrop>
  );
};
