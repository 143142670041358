// Framework and third-party non-ui
import * as React from 'react';
import LayerList from '@arcgis/core/widgets/LayerList';

// Hooks, context, and constants
import { layerCollection } from './constants.js';

// App components
import { LayerCard } from './LayerCard';

// JSON & Styles
import { SidebarHeader, SidebarSection } from './Sidebar-styled';

// Third-party components (buttons, icons, etc.)

export const LayerListContainer = ({ mapView }) => {
  if (!mapView) return 'loading';

  const layerListRef = React.useRef();

  React.useEffect(() => {
    if (!mapView) return;

    // LayerList
    const layerList = new LayerList({
      container: layerListRef.current,
      view: mapView,
    });
    window.layerList = layerList;
    window.mapView = mapView;
    return () => {
      layerList.destroy();
    };
  }, []);

  const toggleLayer = (layer) => {
    console.log('Toggle layer', layer);
  };

  const getLayers = (layerCollection) => {
    return layerCollection.map((layer, i) => {
      const { name, label, thumbnailUrl } = layer;
      return {
        id: i,
        name,
        label,
        thumbnailUrl,
        selected: true,
      };
    });
  };

  return (
    <div>
      <SidebarHeader>
        <div
          aria-hidden="true"
          className="sidebar-header-icon esri-icon-layers"
        />
        Layers
      </SidebarHeader>
      <SidebarSection>
        {/* <LayerCard layers={getLayers(layerCollection)} onSelect={toggleLayer} /> */}
        <div ref={layerListRef}></div>
      </SidebarSection>

      {/* <div ref={layerListRef}></div> */}
    </div>
  );
};
