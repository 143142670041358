import { subHours, addHours, format } from 'date-fns';

export const getPreviousHourInUTC = (hoursToSubtract = 1) => {
  const prevHour = subHours(new Date(), hoursToSubtract);

  const prevHourInUtc = new Date(
    prevHour.getUTCFullYear(),
    prevHour.getUTCMonth(),
    prevHour.getUTCDate(),
    prevHour.getUTCHours(),
    prevHour.getUTCMinutes(),
    prevHour.getUTCSeconds()
  );

  // const prevHourInUtcFormatted = format(prevHourInUtc, 'yyyy-MM-dd HH:mm:ss');

  return prevHourInUtc;
};
export const getAddSubHour = ({ hoursToModify = 1, round = false }) => {
  const date = new Date();

  if (round) {
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  if (hoursToModify > 0) {
    const futureHour = addHours(date, hoursToModify);
    return futureHour;
  } else {
    const prevHour = subHours(date, Math.abs(hoursToModify));
    return prevHour;
  }
};

export const formatDateTime = (datetime) => {
  const utc = new Date(
    datetime.getUTCFullYear(),
    datetime.getUTCMonth(),
    datetime.getUTCDate(),
    datetime.getUTCHours(),
    datetime.getUTCMinutes(),
    datetime.getUTCSeconds()
  );

  const fDateTime = format(utc, 'yyyy-MM-dd HH:mm:ss');

  return fDateTime;
};
