import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import Search from '@arcgis/core/widgets/Search';
import Locate from '@arcgis/core/widgets/Locate';
import Compass from '@arcgis/core/widgets/Compass';

export const initMap = (webmapId) => {
  console.log(`Loading webmap: ${webmapId}`);
  const webmap = new WebMap({
    portalItem: {
      id: webmapId,
    },
  });

  return webmap;
};

export const initView = (webmap, mapRef) => {
  const view = new MapView({
    map: webmap,
    container: mapRef.current,
    constraints: {
      rotationEnabled: true,
    },
  });

  const search = new Search({
    view: view,
    container: document.createElement('div'),
    enableInfoWindow: false,
    popupEnabled: false,
    locationEnabled: false,
  });

  const locate = new Locate({
    view: view,
  });

  const compass = new Compass({
    view: view,
  });

  view.ui.add(search, 'top-right');
  view.ui.add(locate, 'top-right');
  if (window.innerWidth < 720) {
    view.ui.remove('zoom');
  } else {
    view.ui.move('zoom', 'top-right');
  }

  return { view, widgets: { compass, locate, search } };
};
