import styled from 'styled-components';

export const FeedbackWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const FeedbackButton = styled.a`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  background: #096bc8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  border: none;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;

  color: #ffffff;
`;
