// Framework and third-party non-ui
import * as React from 'react';

// Hooks, context, and constants

// App components
import { ToggleTimeFilter } from 'components/ArcHydroComponents';

// JSON & Styles

// Third-party components (buttons, icons, etc.)

export const PredictionsToggleFilter = ({ intervals = [], layer = {} }) => {
  // State and Constants

  // Actions

  // Effects

  return (
    <ToggleTimeFilter intervals={intervals} layers={[layer]} round={true} />
  );
};
