const fetchPin2FloodPolygon = async (objectid, floodLayer) => {
  if (!objectid) {
    return null;
  }

  const { fields } = window.Pin2Flood.LayersConfig.PINDROP_FLOOD_POLYGONS_TITLE;
  const OID_FIELD = fields[2].name;

  const params = {
    where: `${OID_FIELD}=${objectid}`,
    returnGeometry: true,
    outFields: '*',
    f: 'json',
  };

  let query = floodLayer.createQuery();
  query = { ...query, ...params };

  try {
    const data = await floodLayer.queryFeatures(query);

    const { features } = data || {};

    return features && features[0] ? features[0] : null;
  } catch (err) {
    console.error(err);
  }
};

export const getFloodFeature = async ({
  pinDropGeometry,
  fieldNameForHid,
  floodLayer,
}) => {
  const { fields } = window.Pin2Flood.LayersConfig.PINDROP_FLOOD_POLYGONS_TITLE;
  const OID_FIELD = fields[2].name;

  const params = {
    where: '1=1',
    geometry: pinDropGeometry,
    geometryType: 'esriGeometryPoint',
    spatialRel: 'esriSpatialRelIntersects',
    returnGeometry: true,
    outFields: '*',
    orderByFields: fieldNameForHid,
    f: 'json',
  };

  let query = floodLayer.createQuery();
  query = { ...query, ...params };

  try {
    // Get all iutersecting features oids only
    const data = await floodLayer.queryFeatures(query);

    const { features } = data || {};

    if (!features?.length) {
      console.log('no pin2flood polygon found');
      return null;
    }

    // Get geometry and attributes from select intersecting feature
    const objectid = features[0].attributes[OID_FIELD];
    const floodFeature = await fetchPin2FloodPolygon(objectid, floodLayer);

    return floodFeature;
  } catch (err) {
    console.error('pin2Flood', err);
    return null;
  }
};

export const getAdjacentFloodFeatures = async ({ flood, floodLayer }) => {
  const { fields } = window.Pin2Flood.LayersConfig.PINDROP_FLOOD_POLYGONS_TITLE;
  // const STREAMID_FIELD = fields[4].name;
  const COMPOSITEID_FIELD = fields[1].name;
  const CONNLIST_FIELD = fields[5].name;

  const params = {
    where: `${COMPOSITEID_FIELD} in (${flood.attributes[CONNLIST_FIELD]})`,
    returnGeometry: true,
    outFields: ['*'],
    f: 'json',
  };

  let query = floodLayer.createQuery();
  query = { ...query, ...params };

  try {
    const data = await floodLayer.queryFeatures(query);

    const { features } = data || {};

    if (!features?.length) {
      console.log('no adjacent flood polygons found');
      return null;
    }

    console.log(flood.attributes, features);
    return features;

    // const uniqueFloodStreams = features.filter(
    //   (val, idx) =>
    //     features.findIndex(
    //       (f) => f.attributes[STREAMID_FIELD] === val.attributes[STREAMID_FIELD]
    //     ) === idx
    // );

    // return uniqueFloodStreams;
  } catch (err) {
    console.error('pin2Flood', err);
    return null;
  }
};

// const { serviceUrl, token } = Config;
export const savePin2FloodPolygon = async ({
  geometry,
  attributes,
  fields,
  pin2FloodLayer,
}) => {
  const {
    userId,
    userFullName,
    compositeId,
    pinDropTime,
    pindropId,
    ObjectId,
    pinType,
  } = attributes;

  const feature = {
    geometry: geometry,
    attributes: {
      [fields[0]['name']]: userId,
      [fields[1]['name']]: userFullName,
      [fields[2]['name']]: pindropId,
      [fields[3]['name']]: pinDropTime,
      [fields[4]['name']]: compositeId,
      [fields[5]['name']]: pinType,
    },
  };

  if (ObjectId) {
    feature.attributes.ObjectId = ObjectId;

    const edits = {
      updateFeatures: [feature],
    };
    const options = {};

    const { updateFeatureResults } = await pin2FloodLayer.applyEdits(
      edits,
      options
    );

    return updateFeatureResults[0].objectId ? updateFeatureResults[0] : null;
  } else {
    const edits = {
      addFeatures: [feature],
    };
    const options = {};

    const { addFeatureResults } = await pin2FloodLayer.applyEdits(
      edits,
      options
    );

    return addFeatureResults[0].objectId ? addFeatureResults[0] : null;
  }
};

export const savePindrop = async ({
  geometry,
  attributes,
  fields,
  pinDropLayer,
}) => {
  const { userId, userFullName, compositeId, pinDropTime, pinType, ObjectId } =
    attributes;

  const feature = {
    geometry: geometry,
    attributes: {
      [fields[0]['name']]: userId,
      [fields[1]['name']]: userFullName,
      [fields[2]['name']]: pinDropTime,
      [fields[3]['name']]: compositeId,
      [fields[4]['name']]: pinType,
    },
  };

  if (ObjectId) {
    feature.attributes.ObjectId = ObjectId;

    const edits = {
      updateFeatures: [feature],
    };
    const options = {};

    const { updateFeatureResults } = await pinDropLayer.applyEdits(
      edits,
      options
    );

    return updateFeatureResults[0].objectId ? updateFeatureResults[0] : null;
  } else {
    const edits = {
      addFeatures: [feature],
    };
    const options = {};

    const { addFeatureResults } = await pinDropLayer.applyEdits(edits, options);

    return addFeatureResults[0].objectId ? addFeatureResults[0] : null;
  }
};
