// Framework and third-party non-ui
import * as React from 'react';
import BasemapGalleryVM from '@arcgis/core/widgets/BasemapGallery/BasemapGalleryViewModel';
import Basemap from '@arcgis/core/Basemap';

// Hooks, context, and constants

// App components
import { LayerCard } from './LayerCard';

// JSON & Styles
import { basemapCollection } from './constants.js';
import { SidebarHeader, SidebarSection } from './Sidebar-styled';

// Third-party components (buttons, icons, etc.)

export const BasemapGalleryContainer = ({ mapView }) => {
  if (!mapView) return 'loading';
  const [mapGallery, setMapGallery] = React.useState(null);
  const [selectedBasemapId, setSelectedBasemapId] = React.useState(0);
  const basemapGalleryRef = React.useRef();

  React.useEffect(() => {
    if (!mapView) return;

    const source = basemapCollection.map((b) => {
      return Basemap.fromId(b.name);
    });

    const mapGallery = new BasemapGalleryVM({
      source,
      activeBasemap: basemapCollection[0].name,
      view: mapView,
    });

    setMapGallery(mapGallery);

    return () => {
      mapGallery.destroy();
    };
  }, []);

  const setBasemap = (basemap) => {
    setSelectedBasemapId(basemap.id);
    mapGallery.activeBasemap = mapGallery.items.getItemAt(basemap.id).basemap;
  };

  const getLayersFromBasemaps = (basemapCollection, mapGallery) => {
    return basemapCollection.map((basemap, i) => {
      const { name, label } = basemap;
      return {
        id: i,
        name,
        label,
        thumbnailUrl: mapGallery.items.getItemAt(i).basemap.thumbnailUrl,
        selected: selectedBasemapId === i,
      };
    });
  };

  return (
    <>
      <SidebarHeader>
        <div
          aria-hidden="true"
          className="sidebar-header-icon esri-icon-basemap"
        />
        Maps
      </SidebarHeader>
      <SidebarSection>
        <LayerCard
          layers={
            mapGallery && getLayersFromBasemaps(basemapCollection, mapGallery)
          }
          onSelect={setBasemap}
        />
      </SidebarSection>
    </>
  );
};
