// Framework and third-party non-ui
import * as React from 'react';

// Hooks, context, and constants

// App components

// JSON & Styles
import { FeedbackWrapper, FeedbackButton } from './Feedback-styled';

// Third-party components (buttons, icons, etc.)

export const Feedback = () => {
  // State and Constants
  const { FeedbackConfig } = window.Pin2Flood;
  const { toAddress, ccAddress, bccAddress, subject, body } = FeedbackConfig;

  const cc = ccAddress ? `cc=${ccAddress}` : null;
  const bcc = bccAddress ? `bcc=${bccAddress}` : null;
  const _subject = subject ? `subject=${subject}` : null;
  const _body = body ? `body=${body}` : null;

  const mailtoOptions = [cc, bcc, _subject, _body].filter(Boolean).join('&');

  const mailtoString = `mailto:${toAddress}${
    mailtoOptions.length ? `?${mailtoOptions}` : null
  }`;

  return (
    <FeedbackWrapper>
      <FeedbackButton href={mailtoString} target="_blank">
        <div aria-hidden="true" className="button-icon esri-icon-contact" />
        Send feedback!
      </FeedbackButton>
    </FeedbackWrapper>
  );
};
