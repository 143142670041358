// Framework and third-party non-ui
import * as React from 'react';

// Hooks, context, and constants

// App components
import { ToggleTimeFilter } from 'components/ArcHydroComponents';

// JSON & Styles

// Third-party components (buttons, icons, etc.)

export const PinsToggleFilter = ({
  intervals = [],
  layers = [],
  updatePinDrops,
}) => {
  // State and Constants
  const { LayersConfig } = window.Pin2Flood;
  const pinDropConfig = LayersConfig['PINDROP_POINTS'];

  const fieldNameForTime = pinDropConfig['fields'][2]['name'];

  // Actions

  // Effects

  return (
    <ToggleTimeFilter
      intervals={intervals}
      layers={layers}
      onChange={updatePinDrops}
      timeField={fieldNameForTime}
    />
  );
};
