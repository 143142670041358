// Framework and third-party non-ui
import * as React from 'react';

// Hooks, context, and constants

// App components
import { Loader } from 'components/Loader';

// JSON & Styles
import {
  StyledLRButton,
  StyledInnerContainer,
  StyledContentContainer,
} from './DropPinWidget-styled';

// Third-party components (buttons, icons, etc.)

const saveIcon = (
  <div
    style={{ fontSize: '20px', paddingRight: '5px' }}
    aria-hidden="true"
    className="esri-icon-save"
  ></div>
);

const undoIcon = (
  <div
    style={{ fontSize: '20px', paddingRight: '5px' }}
    aria-hidden="true"
    className="esri-icon-trash"
  ></div>
);

export const DropPinResults = ({
  onRestart,
  onSaveComplete,
  onError,
  handleSavePinDrop,
  saveStatus,
}) => {
  // State and Constants
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  // Actions

  const handleSaveClick = async () => {
    await handleSavePinDrop();
  };

  const handleUndoClick = () => {
    onRestart();
  };

  // Effects
  React.useEffect(() => {
    const loading = saveStatus?.status === 'loading';
    const error = saveStatus?.status === 'failed';
    const saved = saveStatus?.status === 'success';

    if (saved) {
      onSaveComplete();
    }
    setIsLoading(loading);
    setIsError(error);
  }, [saveStatus]);

  React.useEffect(() => {
    if (isError) {
      const response = onError(saveStatus);
      if (response) {
        setIsError(false);
      }
    }
  }, [isError]);

  return (
    <StyledContentContainer>
      <StyledInnerContainer>
        <StyledLRButton
          selected={true}
          position="left"
          onClick={handleSaveClick}
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : saveIcon}
          Save
        </StyledLRButton>
        <StyledLRButton
          selected={false}
          position="right"
          onClick={handleUndoClick}
          disabled={isLoading}
        >
          {undoIcon}
          Undo
        </StyledLRButton>
      </StyledInnerContainer>
    </StyledContentContainer>
  );
};
