// Framework and third-party non-ui
import * as React from 'react';
import { useAnimation } from 'framer-motion';

// Hooks, context, and constants
import logo from 'images/pin2flood.png';

// App components

// JSON & Styles
import {
  DrawerWrapper,
  DrawerOverlay,
  DrawerContent,
  DrawerControl,
  RectangleIcon,
  DrawerTopHover,
} from './Drawer-styled';

// Third-party components (buttons, icons, etc.)

export const Drawer = ({
  overlayColor = 'transparent',
  height = 300,
  padding = 100,
  initial = 'closed',
  children,
  topHover = <></>,
}) => {
  // State and Constants
  const topRef = React.useRef();
  const [drawerState, setDrawerState] = React.useState(initial);
  const controls = useAnimation();

  const drawerContentStyles = {
    active: {
      y: 0,
    },
    inactive: {
      y: height - padding,
    },
  };

  const drawerControlStyles = {
    active: {
      y: 0,
    },
    inactive: {
      y: 0,
    },
  };

  const drawerTopHoverStyles = {
    active: {
      y: 0,
    },
    inactive: {
      y: 0,
    },
  };

  // Actions

  // Effects
  React.useEffect(() => {
    // play open and close animation when not in partial state
    if (drawerState !== 'partial') {
      controls.start(drawerState === 'open' ? 'active' : 'inactive');
    }
  }, [drawerState, controls]);

  return (
    <DrawerWrapper height={height}>
      <DrawerOverlay overlayColor={overlayColor} />

      <DrawerContent
        id="drawer-content"
        height={height}
        padding={padding}
        drag="y"
        dragElastic={0.1}
        dragConstraints={{
          top: 0, //window.innerHeight - height,
          bottom: height, //window.innerHeight,
        }}
        dragMomentum={false}
        onDragEnd={(_event, info) => {
          const isDraggingDown = info.offset.y > 0;
          // close when drag down is 2/3 of original size
          // open when drag up is 1/3 of size
          const multiplier = isDraggingDown ? 0.4 : 0.6;
          const threshold = window.innerHeight - height * multiplier;

          if (
            isDraggingDown &&
            Math.abs(info.point.y) > threshold &&
            drawerState !== 'closed'
          ) {
            setDrawerState('closed');
          } else if (
            !isDraggingDown &&
            Math.abs(info.point.y) < threshold &&
            drawerState !== 'open'
          ) {
            setDrawerState('open');
          } else {
            // controls.start(drawerState === "closed" ? "inactive" : "active");
            if (drawerState === 'closed' && !isDraggingDown) {
              setDrawerState('partial');
            }
            if (drawerState === 'closed' && isDraggingDown) {
              setDrawerState('partial');
              setDrawerState('closed');
            }
            if (drawerState === 'open' && isDraggingDown) {
              setDrawerState('partial');
            }
          }
        }}
        animate={controls}
        variants={drawerContentStyles}
        transition={{ type: 'spring', damping: 30, stiffness: 180 }}
      >
        <DrawerTopHover
          variants={drawerTopHoverStyles}
          transition={{ type: 'spring', damping: 30, stiffness: 180 }}
          ref={topRef}
        >
          {topHover}
        </DrawerTopHover>

        <DrawerControl
          aria-label="drawer control"
          onTap={() =>
            setDrawerState((s) => (s === 'open' ? 'closed' : 'open'))
          }
          variants={drawerControlStyles}
          transition={{ type: 'spring', damping: 30, stiffness: 180 }}
        >
          <RectangleIcon />
        </DrawerControl>

        <div style={{ height: '55px' }}>
          <img src={logo} alt="Pin2Flood Logo" style={{ height: '43px' }} />
        </div>
        {children}
      </DrawerContent>
    </DrawerWrapper>
  );
};
