import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  touch-action: none;
  pointer-events: none;
  z-index: 2;
`;

export const SidebarContainer = styled(motion.div)`
  position: fixed;
  right: -320px;
  background-color: ${({ color }) => `${color}`};
  pointer-events: ${({ $isopen }) => `${$isopen ? 'auto' : 'none'}`};
  width: ${({ width }) => `${width}px`};
  height: 100%;
  box-sizing: border-box;
  box-shadow: 16px 0 32px -16px #000;
`;

export const ToggleContainer = styled(motion.div)`
  top: 0;
  right: 0;
  margin-top: 75px;
  margin-right: 15px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #323232;
  background-color: #fff;
  overflow: visible;
  height: 56px;
  width: 56px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 40px;
  cursor: pointer;
  z-index: 0;
  pointer-events: auto;
  touch-action: auto;

  .esri-icon-expand {
    font-size: 24px;
  }
`;

export const SidebarContent = styled.div`
  padding: 0 10px;
`;

export const CloseContainer = styled(motion.div)`
  top: 0;
  left: 0;
  margin: 20px 10px;
  z-index: 2;
  touch-action: auto;
  pointer-events: auto;
`;

export const SidebarHeader = styled.h2`
  margin-bottom: 15px;
`;

export const SidebarSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 1rem;
`;

export const LayerCardContainer = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
`;

export const LayerCardImage = styled.img`
  display: flex;
  height: 64px;
  width: 64px;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 1px;
  object-fit: cover;
  border: ${({ selected }) =>
    `${selected ? '3px solid dodgerblue' : '3px solid white'}`};
`;

export const LayerCardLabel = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
  margin-bottom: 1rem;
`;
